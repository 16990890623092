var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topbar"},[(
      _vm.hasRole('superadministrator') ||
      _vm.hasRole('companyadmin') ||
      _vm.hasRole('companyworker') ||
      _vm.hasRole('local_cluster_manager')
    )?_c('RSTopbarItem',{attrs:{"badge-content":_vm.breakdownCount,"icon":"mdi-hammer-wrench","tooltip":"Arızalar","list":_vm.breakdownList},on:{"click:show-all":_vm.handleBreakdownsListClick}}):_vm._e(),(
      _vm.hasRole('superadministrator') ||
      _vm.hasRole('companyadmin') ||
      _vm.hasRole('companyworker') ||
      _vm.hasRole('local_cluster_manager')
    )?_c('RSTopbarItem',{attrs:{"badge-content":_vm.complaintCount,"icon":"mdi-clipboard-alert","tooltip":"Şikayetler","list":_vm.complaintList},on:{"click:show-all":_vm.handleComplaintsListClick}}):_vm._e(),(
      _vm.hasRole('superadministrator') ||
      _vm.hasRole('companyadmin') ||
      _vm.hasRole('companyworker') ||
      _vm.hasRole('local_cluster_manager')
    )?_c('RSTopbarItem',{attrs:{"badge-content":_vm.suggestionCount,"icon":"mdi-lightbulb-outline","tooltip":"Öneriler","list":_vm.suggestionList},on:{"click:show-all":_vm.handleSuggestionsListClick}}):_vm._e(),(
      _vm.hasRole('superadministrator') ||
      _vm.hasRole('companyadmin') ||
      _vm.hasRole('companyworker') ||
      _vm.hasRole('local_cluster_manager')
    )?_c('RSTopbarItem',{attrs:{"badge-content":_vm.reminderCount,"icon":"mdi-alarm-multiple","tooltip":"Hatırlatıcılar","list":_vm.reminderList},on:{"click:show-all":_vm.handleReminderListClick}}):_vm._e(),(
      _vm.hasRole('superadministrator') ||
      _vm.hasRole('companyadmin') ||
      _vm.hasRole('companyworker') ||
      _vm.hasRole('local_cluster_manager')
    )?_c('RSTopbarItem',{attrs:{"icon":"mdi-spray-bottle","badge-content":_vm.maintenanceCount,"tooltip":"Bakım, Temizlik, Kontrol","list":_vm.maintenanceList},on:{"click:show-all":_vm.handleMaintenanceListClick}}):_vm._e(),(
      _vm.hasRole('superadministrator') ||
      _vm.hasRole('companyadmin') ||
      _vm.hasRole('companyworker') ||
      _vm.hasRole('local_cluster_manager')
    )?_c('RSTopbarItem',{attrs:{"badge-content":_vm.bankActivityCount,"icon":"mdi-bank-outline","tooltip":"Banka İşlemleri","routeLink":'/incomes/bank/open'}}):_vm._e(),_c('KTQuickUser')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }