<template>
  <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <KTAside v-if="asideEnabled"></KTAside>
      <!-- end:: Aside Left -->

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <!-- TODO: Remove content class to remove padding -->
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid"
        >
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <!-- <KTSubheader
            v-if="subheaderDisplay"
            v-bind:breadcrumbs="breadcrumbs"
            v-bind:title="pageTitle"
          /> -->
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid,
              }"
            >
              <transition name="fade-in-up">
                <v-app>
                  <transition name="fade-in-up">
                    <router-view></router-view>
                  </transition>

                  <RSQuickAction />
                </v-app>
              </transition>
            </div>
          </div>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
    <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar>

    <div
      class="payment-suspension"
      v-if="paymentWarningLevel && !hideAlert"
      style="
        background: yellow;
        position: fixed;
        line-height: 50px;
        text-align: center;
        font-weight: bold;
        bottom: 0;
        width: 100vw;
        z-index: 100;
        color: #333;
      "
      :style="paymentWarningStyle"
    >
      <template v-if="paymentWarningLevel === 1">
        Gecikmiş faturanız bulunmaktadır. Ödemenizi zamanında yapmanızı rica
        ederiz.
      </template>
      <template v-else-if="paymentWarningLevel === 2">
        Gecikmiş faturanız bulunmaktadır. Hizmetinizin aksamaması için ödemenizi
        zamanında yapmanızı rica ederiz.
      </template>
      <template v-else-if="paymentWarningLevel === 3">
        Gecikmiş faturanız bulunmaktadır. Hizmetiniz durdurulmuştur. Sisteminizi
        tekrar kullanabilmek için gecikmiş ödemelerinizi yapmanız gerekmektedir.
      </template>
      <v-btn
        small
        outlined
        icon
        color="pink"
        @click="hidePaymentAlert"
        style="color: white"
        ><v-icon color="#333">mdi-close</v-icon></v-btn
      >
    </div>

    <div
      class="temporary-user"
      style="
        background: steelblue;
        position: fixed;
        line-height: 50px;
        text-align: center;
        font-weight: bold;
        bottom: 0;
        width: 100vw;
        z-index: 100;
        color: white;
      "
      v-if="hasTemporaryToken"
    >
      Başka bir kullanıcı olarak giriş yaptınız. Kendi oturumunuza dönmek için
      <a @click.prevent="handleRemoveTemporaryUserClick" color="pink" href="#"
        >tıklayın.</a
      >
      <v-btn
        small
        outlined
        icon
        color="pink"
        @click="hasTemporaryToken = false"
        style="color: white"
        ><v-icon color="white">mdi-close</v-icon></v-btn
      >
    </div>
  </div>
</template>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
// import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
// import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/content/Loader.vue";
import RSQuickAction from "@/view/layout/extras/offcanvas/QuickAction";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import { hasPermissions } from "@/view/mixins";
import JwtService from "@/core/services/jwt.service";
import { LOGOUT } from "@/core/services/store/auth.module";

export default {
  name: "Layout",
  mixins: [hasPermissions],
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    // KTSubheader,
    KTStickyToolbar,
    // KTScrollTop,
    Loader,
    RSQuickAction,
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    } else if (this.currentUser.consented_on === null) {
      this.$router.push({ name: "consent" });
    }

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

      if (JwtService.hasTemporaryToken()) {
        this.hasTemporaryToken = true;
      }
    }, 2000);
  },
  watch: {
    currentUser: {
      deep: true,
      handler() {
        if (this.currentUser.consented_on === null) {
          this.$router.push({ name: "consent" });
        }
      },
    },
  },
  methods: {
    hidePaymentAlert() {
      this.hideAlert = true;
    },
    handleRemoveTemporaryUserClick() {
      this.$store.dispatch(LOGOUT).then(() => {
        JwtService.removeTemporaryToken();
        window.location.href = "/admin/users";
      });
    },
  },
  data() {
    return {
      hideAlert: false,
      hasTemporaryToken: false,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "cluster",
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return false;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },

    /**
     * Returns payment warning level.
     * - 0: No warning
     * - 1: First warning
     * - 2: Second warning
     * - 3: Shutdown
     * - 4: Delete data
     * @returns {Number}
     */
    paymentWarningLevel() {
      if (
        !this.hasRole("companyadmin") &&
        !this.hasRole("local_cluster_manager") &&
        !this.hasRole("superadministrator")
      ) {
        return null;
      }

      if (!this.cluster || this.cluster.customer === null) {
        return null;
      }

      return this.cluster.customer.payment_warning_level;
    },

    /**
     * @returns {object}
     */
    paymentWarningStyle() {
      if (!this.paymentWarningLevel) {
        return {};
      }

      if (this.paymentWarningLevel === 1) {
        return { background: "yellow", color: "#333" };
      }

      return { background: "red", color: "#fff" };
    },
  },
};

// vuetify conflict style issue
// https://github.com/vuetifyjs/vuetify/issues/8530

// workaround
// https://github.com/vuetifyjs/vuetify/issues/9999
const ignoreWarnMessage = `The .native modifier for v-on is only valid on components but it was used on <div>.`;
// eslint-disable-next-line no-unused-vars
Vue.config.warnHandler = function (msg, vm, trace) {
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    // eslint-disable-next-line no-unused-vars
    trace = null;
  }
};
</script>
