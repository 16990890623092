<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <RSTopbarItem
      :badge-content="breakdownCount"
      icon="mdi-hammer-wrench"
      tooltip="Arızalar"
      v-if="
        hasRole('superadministrator') ||
        hasRole('companyadmin') ||
        hasRole('companyworker') ||
        hasRole('local_cluster_manager')
      "
      @click:show-all="handleBreakdownsListClick"
      :list="breakdownList"
    />

    <RSTopbarItem
      :badge-content="complaintCount"
      icon="mdi-clipboard-alert"
      tooltip="Şikayetler"
      v-if="
        hasRole('superadministrator') ||
        hasRole('companyadmin') ||
        hasRole('companyworker') ||
        hasRole('local_cluster_manager')
      "
      @click:show-all="handleComplaintsListClick"
      :list="complaintList"
    />

    <RSTopbarItem
      :badge-content="suggestionCount"
      icon="mdi-lightbulb-outline"
      tooltip="Öneriler"
      v-if="
        hasRole('superadministrator') ||
        hasRole('companyadmin') ||
        hasRole('companyworker') ||
        hasRole('local_cluster_manager')
      "
      @click:show-all="handleSuggestionsListClick"
      :list="suggestionList"
    />

    <RSTopbarItem
      :badge-content="reminderCount"
      icon="mdi-alarm-multiple"
      tooltip="Hatırlatıcılar"
      v-if="
        hasRole('superadministrator') ||
        hasRole('companyadmin') ||
        hasRole('companyworker') ||
        hasRole('local_cluster_manager')
      "
      @click:show-all="handleReminderListClick"
      :list="reminderList"
    />

    <RSTopbarItem
      icon="mdi-spray-bottle"
      :badge-content="maintenanceCount"
      tooltip="Bakım, Temizlik, Kontrol"
      v-if="
        hasRole('superadministrator') ||
        hasRole('companyadmin') ||
        hasRole('companyworker') ||
        hasRole('local_cluster_manager')
      "
      :list="maintenanceList"
      @click:show-all="handleMaintenanceListClick"
    />

    <RSTopbarItem
      :badge-content="bankActivityCount"
      icon="mdi-bank-outline"
      tooltip="Banka İşlemleri"
      v-if="
        hasRole('superadministrator') ||
        hasRole('companyadmin') ||
        hasRole('companyworker') ||
        hasRole('local_cluster_manager')
      "
      :routeLink="'/incomes/bank/open'"
    />

    <!--begin: Language bar -->
    <!-- <div class="topbar-item">
      <b-dropdown
        size="sm"
        variant="link"
        toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
        no-caret
        right
        no-flip
      >
        <template v-slot:button-content>
          <img
            class="h-20px w-20px rounded-sm"
            :src="languageFlag || getLanguageFlag"
            alt=""
          />
        </template>
        <b-dropdown-text tag="div" class="min-w-md-175px">
          <KTDropdownLanguage
            v-on:language-changed="onLanguageChanged"
          ></KTDropdownLanguage>
        </b-dropdown-text>
      </b-dropdown>
    </div> -->
    <!--end: Language bar -->

    <!--begin: User Bar -->
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}

.v-badge__badge.pink {
  background-color: #e91e63;
  border-color: #e91e63;
  inset: auto auto calc(100% - 10px) calc(100% - 14px) !important;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";

// import KTSearchDefault from "@/view/layout/extras/dropdown/SearchDefault.vue";
// import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
// import KTDropdownQuickAction from "@/view/layout/extras/dropdown/DropdownQuickAction.vue";
// import KTDropdownMyCart from "@/view/layout/extras/dropdown/DropdownMyCart.vue";
// import KTDropdownLanguage from "@/view/layout/extras/dropdown/DropdownLanguage.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
// import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";
// import i18nService from "@/core/services/i18n.service.js";
import RSTopbarItem from "./TopbarItem";
import { hasPermissions } from "@/view/mixins";

export default {
  name: "KTTopbar",
  mixins: [hasPermissions],
  computed: {
    ...mapGetters([
      "bankActivityCount",
      "companyId",
      "clusterId",
      "reminderCount",
      "reminderRecentList",
      "breakdownCount",
      "breakdownRecentList",
      "suggestionCount",
      "suggestionRecentList",
      "complaintCount",
      "complaintRecentList",
      "maintenanceCount",
      "maintenanceRecentList",
    ]),
    // getLanguageFlag() {
    //   return this.onLanguageChanged();
    // },
    reminderList() {
      return this.reminderRecentList.map((item) => {
        return {
          title: item.subject,
          subtitle: item.body,
        };
      });
    },
    breakdownList() {
      return this.breakdownRecentList.map((item) => {
        return {
          title: item.subject,
          subtitle: item.status,
        };
      });
    },
    suggestionList() {
      return this.suggestionRecentList.map((item) => {
        return {
          title: item.subject,
          subtitle: item.status,
        };
      });
    },
    complaintList() {
      return this.complaintRecentList.map((item) => {
        return {
          title: item.subject,
          subtitle: item.status,
        };
      });
    },
    maintenanceList() {
      return this.maintenanceRecentList.map((item) => {
        return {
          title: item.subject,
        };
      });
    },
  },
  watch: {
    clusterId() {
      this.tick();
    },
    companyId() {
      this.tick();
    },
  },
  data() {
    return {
      // languageFlag: "",
      // languages: i18nService.languages,
      ticker: null,
    };
  },
  components: {
    RSTopbarItem,
    // KTSearchDefault,
    // KTDropdownNotification,
    // KTDropdownQuickAction,
    // KTDropdownMyCart,
    // KTDropdownLanguage,
    KTQuickUser,
    // KTQuickPanel,
  },
  mounted() {
    this.ticker = window.setInterval(() => this.tick(), 30 * 1000);

    this.$nextTick(() => {
      this.tick();
    });
  },
  destroyed() {
    if (this.ticker !== null) {
      window.clearInterval(this.ticker);
      this.ticker = null;
    }
  },
  methods: {
    ...mapActions([
      "updateBankActivityCount",
      "updateReminderRecentList",
      "updateReminderCount",
      "updateBreakdownRecentList",
      "updateBreakdownCount",
      "updateSuggestionRecentList",
      "updateSuggestionCount",
      "updateComplaintRecentList",
      "updateComplaintCount",
      "updateMaintenanceRecentList",
      "updateMaintenanceCount",
    ]),
    // onLanguageChanged() {
    //   this.languageFlag = this.languages.find((val) => {
    //     return val.lang === i18nService.getActiveLanguage();
    //   }).flag;
    // },
    tick() {
      const params = {};

      let ticker = window.localStorage.getItem("ticker");

      if (ticker) {
        ticker = JSON.parse(ticker);
      }

      const dateNow = Date.now();

      if (
        ticker &&
        ticker.cluster_id === this.clusterId &&
        ticker.company_id === this.companyId &&
        dateNow - ticker.at < 30 * 1000
      ) {
        this.handleTickResponse(ticker.data);
        return;
      }

      if (this.clusterId) {
        params.cluster_id = this.clusterId;
      }

      if (this.companyId) {
        params.company_id = this.companyId;
      }

      this.$api
        .query("ticker", { params })
        .then((response) => {
          window.localStorage.setItem(
            "ticker",
            JSON.stringify({
              at: dateNow,
              cluster_id: this.clusterId,
              company_id: this.companyId,
              data: response.data,
            })
          );
          this.handleTickResponse(response.data);
        })
        .catch((error) => {
          if (error.response?.status === 403) {
            window.clearInterval(this.ticker);
            this.ticker = null;
          }
        });
    },
    handleTickResponse(data) {
      this.updateBreakdownRecentList(data.data.breakdown_list);
      this.updateBreakdownCount(data.meta.breakdown_total);
      this.updateSuggestionRecentList(data.data.suggestion_list);
      this.updateSuggestionCount(data.meta.suggestion_total);
      this.updateComplaintRecentList(data.data.complaint_list);
      this.updateComplaintCount(data.meta.complaint_total);
      this.updateMaintenanceRecentList(data.data.maintenance_schedule_list);
      this.updateMaintenanceCount(data.meta.maintenance_schedule_total);
      this.updateReminderRecentList(data.data.reminder_schedule_list);
      this.updateReminderCount(data.meta.reminder_schedule_total);
      this.updateBankActivityCount(data.meta.bank_activity_total);
    },
    handleReminderListClick() {
      if (this.$router.currentRoute.name !== "communication.reminders.list") {
        this.$router.push({ name: "communication.reminders.list" });
      }
    },
    handleBreakdownsListClick() {
      if (this.$router.currentRoute.name !== "communication.breakdowns.list") {
        this.$router.push({ name: "communication.breakdowns.list" });
      }
    },
    handleSuggestionsListClick() {
      if (this.$router.currentRoute.name !== "communication.suggestions.list") {
        this.$router.push({ name: "communication.suggestions.list" });
      }
    },
    handleComplaintsListClick() {
      if (this.$router.currentRoute.name !== "communication.complaints.list") {
        this.$router.push({ name: "communication.complaints.list" });
      }
    },
    handleMaintenanceListClick() {
      if (
        this.$router.currentRoute.name !== "communication.maintenances.list"
      ) {
        this.$router.push({ name: "communication.maintenances.list" });
      }
    },
  },
};
</script>
