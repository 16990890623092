<template>
  <v-menu top left nudge-top="56px" open-on-hover v-if="clusterId">
    <template v-slot:activator="{ on, attrs }">
      <v-fab-transition>
        <v-btn
          class="quick-action"
          fab
          elevation="6"
          v-on="on"
          v-bind="attrs"
          bottom
          right
          fixed
          color="pink"
          dark
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </template>

    <v-list color="pink" dark>
      <v-list-item
        :to="{ name: 'expenses.invoice.create' }"
        v-if="can('edit-invoice')"
      >
        <v-list-item-content>
          <v-list-item-title>
            <v-icon>mdi-newspaper-minus</v-icon> Fatura Ekle
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="{ name: 'assessments.all.list' }"
        v-if="can('see-assessment')"
      >
        <v-list-item-content>
          <v-list-item-title>
            <v-icon>mdi-receipt-text-check</v-icon> Tahakkuklar
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="{ name: 'incomes.houses.list' }"
        v-if="can('see-house-income')"
      >
        <v-list-item-content>
          <v-list-item-title>
            <v-icon>mdi-home-analytics</v-icon> Mali İşlemler
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        :to="{ name: 'definitions.residents.list' }"
        v-if="can('see-resident')"
      >
        <v-list-item-content>
          <v-list-item-title>
            <v-icon>mdi-account-badge</v-icon> Sakinler
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";

import { hasPermissions } from "@/view/mixins";

export default {
  name: "RSQuickAction",
  mixins: [hasPermissions],
  computed: {
    ...mapGetters(["clusterId"]),
  },
};
</script>
