<template>
  <v-menu
    bottom
    :nudge-bottom="50"
    left
    max-width="360px"
    :close-on-content-click="false"
    v-model="tooltipShown"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: on1, attrs: attrs1 }">
          <v-btn
            class="me-1"
            ref="button"
            icon
            large
            v-on="on"
            v-bind="attrs"
            @click.prevent="routePush"
          >
            <v-badge
              :value="badgeContent"
              :content="badgeContent"
              color="pink"
              bordered
            >
              <v-icon v-on="on1" v-bind="attrs1">{{ icon }}</v-icon>
            </v-badge>
          </v-btn>
        </template>

        {{ tooltip }}
      </v-tooltip>
    </template>

    <slot name="body" v-if="!routeLink">
      <v-list>
        <v-list-item two-line v-for="(item, index) in list" :key="index">
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.subtitle }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="!hideShowAll">
          <v-list-item-content>
            <v-list-item-title>
              <a href="#" @click.prevent="handleShowAllClick">
                <strong>Tümünü Göster</strong>
              </a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </slot>
  </v-menu>
</template>

<script>
export default {
  name: "RSTopbarItem",
  props: {
    icon: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      required: false,
      default: () => [],
    },
    hideShowAll: {
      type: Boolean,
      required: false,
      default: false,
    },
    tooltip: {
      type: String,
      required: false,
      default: "",
    },
    badgeContent: {
      type: String,
      required: false,
      default: "",
    },
    routeLink: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      tooltipShown: false,
    };
  },
  methods: {
    routePush() {
      if (this.routeLink !== "") {
        this.$router.push(this.routeLink);
      }
    },
    handleShowAllClick($event) {
      this.$emit("click:show-all", $event);
      this.tooltipShown = false;
    },
  },
};
</script>
