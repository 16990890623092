<template>
  <!-- <ul class="menu-nav">
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Dashboard </span>
        </a>
      </li>
    </router-link>
  </ul> -->
  <div>
    <ul class="menu-nav">
      <li class="menu-item">
        <v-autocomplete
          :items="companyList"
          :loading="companyListLoading"
          :search-input.sync="companySearch"
          @keypress="handleCompanySearchUpdate"
          :filter="() => true"
          item-text="name"
          item-value="id"
          v-model="selectedCompanyId"
          label="Yönetim şirketi seçin"
          clearable
          style="max-width: 200px"
          :hide-details="true"
          v-if="hasRole('superadministrator') || companyList.length > 0"
          :disabled="clusterDisabled"
        />
      </li>

      <li class="menu-item">
        <rs-select-cluster
          v-model="selectedClusterId"
          @change:item="handleClusterSelect"
          style="max-width: 200px"
          :company-id="
            this.companyList.length > 0 ? selectedCompanyId : undefined
          "
          :search-params="{ is_active: true }"
          :disabled="clusterDisabled"
        />
        <!-- <rs-autocomplete-cluster
          v-model="selectedClusterId"
          @change:option="handleClusterSelect"
          clearable
          style="max-width: 200px"
          :hide-details="true"
          :company-id="
            this.companyList.length > 0 ? selectedCompanyId : undefined
          "
          ref="autocompleteCluster"
          :extra-params="{ is_active: 1 }"
          :disabled="clusterDisabled"
        /> -->
      </li>
    </ul>

    <v-snackbar v-model="toastOpen" color="green" right top>
      {{ toastText }}
    </v-snackbar>
  </div>
</template>

<script>
// import { debounce } from "@/core/plugins/debounce";
import { mapActions, mapGetters } from "vuex";

import { hasPermissions } from "@/view/mixins";

export default {
  name: "KTMenu",
  mixins: [hasPermissions],
  computed: {
    ...mapGetters(["company", "cluster"]),
    clusterDisabled() {
      if (
        this.$route.name !== "expenses.invoice.create" &&
        (Object.keys(this.$route.params).length > 0 ||
          this.$route.name.includes("create"))
      ) {
        return true;
      } else {
        return false;
      }
    },
    canSeeCompany() {
      return this.can("see-company");
    },
  },
  data() {
    return {
      selectedCompanyId: null,
      selectedClusterId: null,
      /**
       * Company search text
       * @type {String}
       */
      companySearch: null,
      /**
       * True if company list is loading
       * @type {Boolean}
       */
      companyListLoading: false,
      /**
       * Company options
       * @type {Array}
       */
      companyList: [],
      toastOpen: false,
      toastText: "",
    };
  },
  watch: {
    // companySearch: debounce(function (n, o) {
    //   console.log("watch.companySearch", n, o);
    //   this.loadCompanyList();
    // }, 250),
    selectedCompanyId() {
      // console.log("watch.selectedCompanyId");
      this.handleCompanySelected();
    },
    cluster() {
      if (
        this.cluster &&
        this.cluster.id &&
        this.cluster.id !== this.selectedClusterId
      ) {
        if (this.cluster.active_company === null) {
          this.selectedCompanyId = null;
        } else if (
          this.cluster.active_company.company_id !== this.selectedCompanyId
        ) {
          this.selectedCompanyId = this.cluster.active_company.company_id;
        }
        // this.$refs.autocompleteCluster.pushOption(this.cluster);
        this.selectedClusterId = this.cluster.id;
      }
    },
    canSeeCompany() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(["updateCompanyId", "updateClusterId"]),
    handleCompanySearchUpdate() {
      // console.log("handleCompanySearchUpdate");
      this.loadCompanyList();
    },
    init() {
      this.loadCompanyList().then(() => {
        // console.log("loadCompanyList.then");
        if (this.company) {
          for (const index in this.companyList) {
            if (this.companyList[index].id === this.company.id) {
              this.selectedCompanyId = this.company.id;
              break;
            }
          }

          if (this.selectedCompanyId === null) {
            this.companyList.push(this.company);
            this.selectedCompanyId = this.company.id;
          }
        } else if (this.companyList.length === 1) {
          // this.selectedCompanyId = this.companyList[0].id;
          // this.handleCompanySelected();
        }
      });

      this.$nextTick(() => {
        if (this.cluster && this.cluster.id) {
          // this.$refs.autocompleteCluster.pushOption(this.cluster);
          this.selectedClusterId = this.cluster.id;
        }
      });
    },
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    loadCompanyList() {
      if (this.companyListLoading || !this.can("see-company")) {
        return Promise.reject();
      }

      this.companyListLoading = true;
      const params = {
        page: 1,
        query: this.companySearch,
        company_type_id: 1,
        is_active: true,
      };

      return this.$api
        .query("companies", { params })
        .then((response) => {
          this.companyList = response.data.data;
        })
        .finally(() => (this.companyListLoading = false));
    },
    handleCompanySelected() {
      // console.log("handleCompanySelected");
      let selectedCompany = null;
      for (const item of this.companyList) {
        if (item.id === this.selectedCompanyId) {
          selectedCompany = item;
          break;
        }
      }

      if (selectedCompany !== null) {
        this.updateCompanyId(selectedCompany.id);
        this.toastText = selectedCompany.name + " olarak işlem yapıyorsunuz";
        this.toastOpen = true;
      } else {
        this.updateCompanyId(null);
      }
    },
    handleClusterSelect(param) {
      // console.log("handleClusterSelect", param);
      if (param && param.item && param.item.company_id) {
        this.updateCompanyId(param.item.company_id);
        this.selectedCompanyId = param.item.company_id;
      }

      this.updateClusterId(this.selectedClusterId);
    },
  },
};
</script>
